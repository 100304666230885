var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "LayoutDefault px-0 pt-0", staticStyle: { width: "100%" } },
    [
      _c(
        "nav",
        {
          staticClass:
            "navbar navbar-light fixed-top bg-white spainhNavbar py-3 navbar-expand-lg w-100 pe-4",
          class: { "is-pinned": _vm.isFixed, "is-not-pinned": !_vm.isFixed },
        },
        [
          _c("div", { staticClass: "container-fluid ps-0 w-100" }, [
            _vm._m(0),
            _vm._m(1),
            _c(
              "div",
              {
                staticClass: "collapse navbar-collapse ms-auto",
                attrs: { id: "navbarNavAltMarkup" },
              },
              [
                _c(
                  "ul",
                  {
                    staticClass:
                      "ms-auto navbar-nav spainhn-main-menu-text text-monospace border-start",
                  },
                  [
                    _c("li", { staticClass: "nav-item" }, [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          staticStyle: { color: "rgb(77,77,77) !important" },
                          attrs: { href: "/" },
                        },
                        [_vm._v(_vm._s(_vm.$t("default.MAIN_MENU_INICIO")))]
                      ),
                    ]),
                    _c("li", { staticClass: "nav-item border-start" }, [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          staticStyle: { color: "rgb(77,77,77) !important" },
                          attrs: { href: "/castillos" },
                        },
                        [_vm._v(_vm._s(_vm.$t("default.MAIN_MENU_MONUMENTOS")))]
                      ),
                    ]),
                    _c("li", { staticClass: "nav-item h-100 border-start" }, [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          staticStyle: { color: "rgb(77,77,77) !important" },
                          attrs: { href: "/rutas-recomendadas" },
                        },
                        [_vm._v(_vm._s(_vm.$t("default.MAIN_MENU_RUTAS")))]
                      ),
                    ]),
                    _c("li", { staticClass: "nav-item border-start" }, [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          staticStyle: { color: "rgb(77,77,77) !important" },
                          attrs: { href: "/blog" },
                        },
                        [_vm._v(_vm._s(_vm.$t("default.MAIN_MENU_NOTICIAS")))]
                      ),
                    ]),
                    _c(
                      "li",
                      { staticClass: "nav-item dropdown my-auto border-start" },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link dropdown-toggle my-auto",
                            staticStyle: { color: "rgb(77,77,77) !important" },
                            attrs: {
                              href: "#",
                              id: "serviceDropdownLink",
                              role: "button",
                              "data-bs-toggle": "dropdown",
                              "aria-expanded": "false",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("default.MAIN_MENU_SERVICIOS")) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "ul",
                          {
                            staticClass: "dropdown-menu dropdown-menu-end",
                            attrs: { "aria-labelledby": "serviceDropdownLink" },
                          },
                          [
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "/servicios/visitas" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "default.MAIN_MENU_SERVICES_VISITS"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "/servicios/alojamiento" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "default.MAIN_MENU_SERVICES_ALOJAMIENTO"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "/servicios/restaurantes" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "default.MAIN_MENU_SERVICES_RESTAURANTES"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: {
                                    href: "/castillos/servicios/eventos-singulares",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "default.MAIN_MENU_SERVICES_EVENTOS"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: {
                                    href: "/castillos/servicios/experiencias-exclusivas",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "default.MAIN_MENU_SERVICES_EXPERIENCIAS"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "/servicios/bodas" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("default.MAIN_MENU_SERVICES_BODA")
                                    )
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "/servicios/espacios" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "default.MAIN_MENU_SERVICES_ESPACIOS"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "/servicios/exposiciones" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "default.MAIN_MENU_SERVICES_MUSEOS"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _c("li", { staticClass: "nav-item border-start" }, [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          staticStyle: { color: "rgb(77,77,77) !important" },
                          attrs: { href: "/clientes/login" },
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "mt-1 me-1",
                            attrs: { icon: "fa-circle-user" },
                          }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("default.MAIN_MENU_ACCEDER")) +
                              " "
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "li",
                      {
                        staticClass:
                          "nav-item dropdown my-auto border-start d-lg-inline-block d-none",
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link dropdown-toggle my-auto",
                            staticStyle: { color: "rgb(77,77,77) !important" },
                            attrs: {
                              href: "#",
                              id: "carritoDropdownLink",
                              role: "button",
                              "data-bs-toggle": "dropdown",
                              "aria-expanded": "false",
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              staticClass: "my-auto",
                              attrs: { icon: "fa-cart-shopping" },
                            }),
                            _vm._v(" " + _vm._s(_vm.ticketsAmount) + " "),
                          ],
                          1
                        ),
                        _c(
                          "ul",
                          {
                            staticClass: "dropdown-menu dropdown-menu-end",
                            attrs: { "aria-labelledby": "serviceDropdownLink" },
                          },
                          [
                            _vm._l(_vm.itemsInCart, function (item) {
                              return _c(
                                "li",
                                {
                                  key: item.uuid,
                                  staticClass: "dropdown-item",
                                },
                                [
                                  _c("entrada-resumen", {
                                    staticClass: "me-auto",
                                    attrs: { item: item },
                                  }),
                                ],
                                1
                              )
                            }),
                            _vm._m(2),
                            _c("li", [
                              _c(
                                "a",
                                { staticClass: "dropdown-item nav-link" },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      "Importe Total " +
                                        _vm._s(
                                          _vm.totalAPagar.toLocaleString()
                                        ) +
                                        " €"
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                            _vm._m(3),
                          ],
                          2
                        ),
                      ]
                    ),
                  ]
                ),
                _c("span", [
                  _c("div", { staticClass: "dropdown flag-menu-main" }, [
                    _c(
                      "a",
                      {
                        staticClass:
                          "nav-link dropdown-toggle my-auto dropdown-flag",
                        staticStyle: { color: "rgb(77,77,77) !important" },
                        attrs: {
                          href: "#",
                          id: "languageDropdownLink",
                          role: "button",
                          "data-bs-toggle": "dropdown",
                          "aria-expanded": "false",
                        },
                      },
                      [
                        _vm.$i18n.locale === "es"
                          ? _c(
                              "a",
                              {
                                staticClass: "dropdown-item d-inline",
                                attrs: { role: "button" },
                                on: {
                                  click: () => {
                                    _vm.$i18n.locale = "es"
                                    _vm.$store.commit(
                                      "setIdioma",
                                      this.$i18n.locale
                                    )
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  staticStyle: {
                                    width: "30px",
                                    "margin-right": "20px",
                                  },
                                  attrs: {
                                    src: require("../assets/Spain_flag.png"),
                                    alt: "flag",
                                  },
                                }),
                                _vm._v("ES"),
                              ]
                            )
                          : _vm._e(),
                        _vm.$i18n.locale === "en"
                          ? _c(
                              "a",
                              {
                                staticClass: "dropdown-item d-inline",
                                attrs: { role: "button" },
                                on: {
                                  click: () => {
                                    _vm.$i18n.locale = "en"
                                    _vm.$store.commit(
                                      "setIdioma",
                                      this.$i18n.locale
                                    )
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  staticStyle: {
                                    width: "30px",
                                    "margin-right": "20px",
                                  },
                                  attrs: {
                                    src: require("../assets/uk_flag.png"),
                                    alt: "flag",
                                  },
                                }),
                                _vm._v("EN"),
                              ]
                            )
                          : _vm._e(),
                        _vm.$i18n.locale === "fr"
                          ? _c(
                              "a",
                              {
                                staticClass: "dropdown-item d-inline",
                                attrs: { role: "button" },
                                on: {
                                  click: () => {
                                    _vm.$i18n.locale = "fr"
                                    _vm.$store.commit(
                                      "setIdioma",
                                      this.$i18n.locale
                                    )
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  staticStyle: {
                                    width: "30px",
                                    "margin-right": "20px",
                                  },
                                  attrs: {
                                    src: require("../assets/france_flags.png"),
                                    alt: "flag",
                                  },
                                }),
                                _vm._v("FR"),
                              ]
                            )
                          : _vm._e(),
                        _vm.$i18n.locale === "de"
                          ? _c(
                              "a",
                              {
                                staticClass: "dropdown-item d-inline",
                                attrs: { role: "button" },
                                on: {
                                  click: () => {
                                    _vm.$i18n.locale = "de"
                                    _vm.$store.commit(
                                      "setIdioma",
                                      this.$i18n.locale
                                    )
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  staticStyle: {
                                    width: "30px",
                                    "margin-right": "20px",
                                  },
                                  attrs: {
                                    src: require("../assets/germany_flag.png"),
                                    alt: "flag",
                                  },
                                }),
                                _vm._v("DE"),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "ul",
                      {
                        staticClass:
                          "dropdown-menu dropdown-menu-end flag-dropdown-menu",
                        attrs: {
                          role: "menu",
                          tabindex: "-1",
                          "aria-labelledby": "languageDropdownLink",
                        },
                      },
                      [
                        _c("li", [
                          _c(
                            "a",
                            {
                              staticClass: "dropdown-item",
                              attrs: { role: "button" },
                              on: {
                                click: () => {
                                  _vm.$i18n.locale = "es"
                                  _vm.$store.commit(
                                    "setIdioma",
                                    this.$i18n.locale
                                  )
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticStyle: {
                                  width: "30px",
                                  "margin-right": "20px",
                                },
                                attrs: {
                                  src: require("../assets/Spain_flag.png"),
                                  alt: "flag",
                                },
                              }),
                              _vm._v("ES"),
                            ]
                          ),
                        ]),
                        _c("li", [
                          _c(
                            "a",
                            {
                              staticClass: "dropdown-item",
                              attrs: { role: "button" },
                              on: {
                                click: () => {
                                  _vm.$i18n.locale = "en"
                                  _vm.$store.commit(
                                    "setIdioma",
                                    this.$i18n.locale
                                  )
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticStyle: {
                                  width: "30px",
                                  "margin-right": "20px",
                                },
                                attrs: {
                                  src: require("../assets/uk_flag.png"),
                                  alt: "flag",
                                },
                              }),
                              _vm._v("EN"),
                            ]
                          ),
                        ]),
                        _c("li", [
                          _c(
                            "a",
                            {
                              staticClass: "dropdown-item",
                              attrs: { role: "button" },
                              on: {
                                click: () => {
                                  _vm.$i18n.locale = "fr"
                                  _vm.$store.commit(
                                    "setIdioma",
                                    this.$i18n.locale
                                  )
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticStyle: {
                                  width: "30px",
                                  "margin-right": "20px",
                                },
                                attrs: {
                                  src: require("../assets/france_flags.png"),
                                  alt: "flag",
                                },
                              }),
                              _vm._v("FR"),
                            ]
                          ),
                        ]),
                        _c("li", [
                          _c(
                            "a",
                            {
                              staticClass: "dropdown-item",
                              attrs: { role: "button" },
                              on: {
                                click: () => {
                                  _vm.$i18n.locale = "de"
                                  _vm.$store.commit(
                                    "setIdioma",
                                    this.$i18n.locale
                                  )
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticStyle: {
                                  width: "30px",
                                  "margin-right": "20px",
                                },
                                attrs: {
                                  src: require("../assets/germany_flag.png"),
                                  alt: "flag",
                                },
                              }),
                              _vm._v("DE"),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
        ]
      ),
      _c(
        "main",
        { staticClass: "LayoutDefault__main bg-light container-fluid px-0" },
        [
          _vm._t("default"),
          _c(
            "div",
            {
              staticClass:
                "fixed-bottom d-flex justify-content-start d-lg-none",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "p-2 bg-white rounded opacity-75 ms-3 mb-3 shadow-lg",
                  attrs: { role: "button" },
                  on: {
                    click: () => {
                      _vm.$router.push("/carrito")
                    },
                  },
                },
                [
                  _c("font-awesome-icon", {
                    staticClass: "text-align-start",
                    attrs: { size: "1x", icon: "fa-cart-shopping me-auto" },
                  }),
                  _vm._v(" " + _vm._s(_vm.ticketsAmountSmall) + " "),
                ],
                1
              ),
            ]
          ),
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "d-flex w-100 newsletter",
          staticStyle: { "min-height": "30rem" },
          style: { backgroundImage: _vm.newsletterImage },
        },
        [
          _c(
            "div",
            {
              staticClass: "d-flex mx-auto my-auto",
              staticStyle: {
                "min-height": "18em",
                width: "28vw",
                "min-width": "300px",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "mx-auto bg-white px-3 pt-3 pb-3",
                  staticStyle: { "border-radius": "10px" },
                },
                [
                  _c(
                    "b-form",
                    { staticClass: "pb-3" },
                    [
                      _c(
                        "h5",
                        { staticClass: "mx-auto spainhn-subtitle-text" },
                        [
                          _c("small", [
                            _vm._v("Suscríbete a nuestro newsletter"),
                          ]),
                        ]
                      ),
                      _c("p", { staticClass: "spainhn-small-text" }, [
                        _c("strong", [
                          _vm._v(
                            "Recibe novedades y descuentos de Spain Heritage Network y comienza tu aventura"
                          ),
                        ]),
                      ]),
                      _c(
                        "b-form-group",
                        { staticClass: "my-0" },
                        [
                          _c("p", { staticClass: "spainhn-text my-0" }, [
                            _c("strong", [_vm._v("INTRODUCE TU EMAIL")]),
                          ]),
                          _c("b-form-input", {
                            staticClass: "w-50 mx-auto",
                            staticStyle: { "border-radius": "10px" },
                            attrs: {
                              type: "email",
                              size: "sm",
                              placeholder: "hola@ejemplo.com",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-form-checkbox",
                        { staticClass: "text-start d-flex my-3" },
                        [
                          _c("small", [
                            _vm._v("Acepto los "),
                            _c(
                              "a",
                              {
                                staticClass: "link-dark",
                                attrs: { href: "/condiciones-generales" },
                              },
                              [_vm._v("términos y condiciones de privacidad")]
                            ),
                            _vm._v(" de Spain Heritage Network"),
                          ]),
                        ]
                      ),
                      _c(
                        "mdb-btn",
                        {
                          staticClass: "spainhn-button",
                          attrs: { color: "indigo" },
                        },
                        [_vm._v("¡Suscribirme!")]
                      ),
                      _c(
                        "a",
                        {
                          staticClass:
                            "spainhn-small-text mt-3 link-dark d-block",
                          attrs: { href: "/privacidad-cookies" },
                        },
                        [
                          _c("small", [
                            _c("strong", [
                              _vm._v(
                                "Acerca del tratamiento de datos de Spain Heritage Network"
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _c("footer", { staticClass: "LayoutDefault_footer bg-dark text-white" }, [
        _c(
          "div",
          {
            staticClass: "row w-100 pt-4 footer-wrapper m-0",
            staticStyle: {
              "padding-bottom": "6.5rem",
              "justify-content": "center",
            },
          },
          [
            _c(
              "b-col",
              {
                staticClass: "ps-4 mb-4 mb-md-0 footer-logo",
                attrs: { cols: "12", sm: "6", lg: "3" },
              },
              [
                _c("img", {
                  staticClass: "img-fluid w-50",
                  attrs: {
                    src: require("@/assets/LOGO REPAHIS (BLANCO SIN FONDO).png"),
                  },
                }),
              ]
            ),
            _c("b-col", { attrs: { sm: "6", lg: "3" } }, [
              _c("div", { staticClass: "d-flex align-items-start" }, [
                _c(
                  "small",
                  {
                    staticClass: "fw-bold",
                    staticStyle: { "font-size": "small", "text-align": "left" },
                  },
                  [_vm._v("Spain Heritage Network")]
                ),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-column mt-3 text-start spainhn-text",
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "link-light",
                      attrs: { href: "/quienes-somos" },
                    },
                    [
                      _c("small", [
                        _vm._v(_vm._s(_vm.$t("default.FOOTER_WHO"))),
                      ]),
                    ]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "link-light",
                      attrs: { href: "/aviso-legal" },
                    },
                    [
                      _c("small", [
                        _vm._v(_vm._s(_vm.$t("default.FOOTER_LEGAL"))),
                      ]),
                    ]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "link-light",
                      attrs: { href: "/privacidad-cookies" },
                    },
                    [
                      _c("small", [
                        _vm._v(
                          _vm._s(_vm.$t("default.FOOTER_PRIVACY_AND_COOKIES"))
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "link-light",
                      attrs: { href: "/condiciones-generales" },
                    },
                    [
                      _c("small", [
                        _vm._v(_vm._s(_vm.$t("default.FOOTER_SELL_GENERALS"))),
                      ]),
                    ]
                  ),
                  _c(
                    "a",
                    { staticClass: "link-light", attrs: { href: "/contacto" } },
                    [
                      _c("small", [
                        _vm._v(_vm._s(_vm.$t("default.FOOTER_CONTACT"))),
                      ]),
                    ]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "link-light",
                      attrs: { href: "https://admin.spainheritagenetwork.com" },
                    },
                    [
                      _c("small", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("default.FOOTER_ACCESS")) + " "
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ]),
            _c(
              "b-col",
              { staticClass: "ps-0 ps-md-4", attrs: { sm: "6", lg: "3" } },
              [
                _c(
                  "div",
                  { staticClass: "d-flex align-items-start text-start" },
                  [
                    _c(
                      "small",
                      {
                        staticClass: "fw-bold",
                        staticStyle: { "font-size": "small" },
                      },
                      [_vm._v(_vm._s(_vm.$t("default.FOOTER.CONTACT")))]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-column align-items-start text-start mt-3 text-start spainhn-text",
                  },
                  [
                    _c("small", [
                      _vm._v("Email: info@spainheritagenetwork.com"),
                    ]),
                    _c("div", { staticClass: "d-flex mt-3 text-start" }, [
                      _c(
                        "a",
                        {
                          staticClass: "link-light",
                          attrs: {
                            href: "https://www.facebook.com/castillosypalaciosdeespana",
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "me-2",
                            attrs: {
                              icon: "fa-brands fa-facebook-square",
                              size: "2x",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "link-light",
                          attrs: {
                            href: "https://www.instagram.com/redcastillosypalacios/",
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "me-2",
                            attrs: {
                              icon: "fa-brands fa-instagram-square",
                              size: "2x",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "link-light",
                          attrs: {
                            href: "https://www.linkedin.com/company/asociaci%C3%B3n-y-club-de-producto-castillos-y-palacios-tur%C3%ADsticos-de-espa%C3%B1a/",
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "me-2",
                            attrs: {
                              icon: "fa-brands fa-linkedin",
                              size: "2x",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ]
            ),
            _vm.$i18n.locale === "fr" || _vm.$i18n.locale === "de"
              ? _c(
                  "b-col",
                  {
                    staticClass: "ps-4 mb-4 mb-md-0 footer-rightlogo",
                    attrs: { cols: "12", sm: "6", lg: "2" },
                  },
                  [
                    _c("img", {
                      staticClass: "img-fluid",
                      staticStyle: { width: "80%" },
                      attrs: { src: require("@/assets/PIE_MINISTERIO.jpg") },
                    }),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { staticClass: "navbar-brand ms-5", attrs: { href: "/" } }, [
      _c("img", {
        staticClass: "align-text-top d-inline-block",
        staticStyle: { height: "80px", width: "auto" },
        attrs: { src: require("../assets/LOGO REPAHIS.png") },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "navbar-toggler border-0 p-0",
        attrs: {
          type: "button",
          "data-bs-toggle": "collapse",
          "data-bs-target": "#navbarNavAltMarkup",
          "aria-controls": "navbarNavAltMarkup",
          "aria-expanded": "false",
          "aria-label": "Toggle navigation",
        },
      },
      [_c("span", { staticClass: "navbar-toggler-icon" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [_c("hr", { staticClass: "dropdown-divider" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "mx-auto" }, [
      _c(
        "a",
        { staticClass: "dropdown-item nav-link", attrs: { href: "/carrito" } },
        [_c("strong", { staticClass: "mx-auto" }, [_vm._v("Ver carrito")])]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }